<template>
  <b-modal
    id="generate-group-list-template"
    ref="generate-group-list-template"
    :title="title"
    centered
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @show="prepareData"
    @close="closeModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="generateGroupListTemplateRules"
        tag="form"
      >
        <b-form>
          <b-form-group
            :label="'Group List Template'"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Group List Template'"
              rules="required"
            >
              <v-select
                v-model="selectedTemplate"
                label="name"
                :placeholder="'Select Template'"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupListTemplatesOptions"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="generateMode === 'groupType' && !showSuccessMessage"
            label="Export type"
          >
            <b-form-radio
              v-model="isSendByEmail"
              class="mb-1"
              name="exportType"
              :value="false"
            >
              Download file
            </b-form-radio>
            <b-form-radio
              v-model="isSendByEmail"
              name="exportType"
              :value="true"
            >
              Send file by email
            </b-form-radio>
          </b-form-group>

          <div
            v-if="generateMode === 'groupType' && !showSuccessMessage && isSendByEmail"
          >
            <hr>
            <h5
              class="text-center py-1"
            >
              This process may take a while. You will receive an email with Letters file when complete. Please enter email
              below.
            </h5>
            <b-form-group
              class="mb-2"
            >
              <!-- Email -->
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email to send"
                  label-for="email"
                >
                  <b-form-input
                    v-model="selectedEmail"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </div>

          <h5
            v-if="generateMode === 'groupType' && showSuccessMessage"
            class="text-center py-1"
          >
            The process may take a while. You will receive an email with generated badges file when complete.
          </h5>

        </b-form>
      </validation-observer>
    </b-overlay>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading && showSuccessMessage"
        @click="generateGroupListTemplate"
      >
        Generate
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  VBModal,
  BForm,
  BFormGroup,
  BButton,
  BOverlay, BFormRadio, BFormInput,
} from 'bootstrap-vue'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormInput,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BFormGroup,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [downloadExportFile],
  props: {
    groupTypeId: {
      type: String,
      required: true,
    },
    generateMode: {
      type: String,
      default: 'group',
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isLoading: false,
      selectedTemplate: null,
      groupListTemplatesOptions: [],
      isSendByEmail: false,
      showSuccessMessage: false,
      selectedEmail: null,
      required,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    title() {
      return this.generateMode === 'groupType' ? 'Bulk Generate Group List Template' : 'Generate Group List Template'
    },
  },
  methods: {
    async prepareData() {
      this.selectedEmail = this.userData.email ?? ''
      this.isSendByEmail = false

      await this.fetchGroupListTemplatesList()
    },
    async fetchGroupListTemplatesList() {
      const queryParams = {
        program_id: this.programId,
        group_type_id: this.groupTypeId,
      }
      const response = await axios.get('/auth/group-list-templates', {
        params: queryParams,
      })
      this.groupListTemplatesOptions = response.data.data
    },
    async closeModal() {
      this.showSuccessMessage = false
      this.selectedTemplate = null
      this.$refs['generate-group-list-template'].hide()
      this.$emit('close')
    },
    async generatePdf() {
      try {
        const queryParams = {
          group_list_template_id: this.selectedTemplate.id,
          group_id: this.groupId,
        }
        await axios.post('/auth/group-list-templates/generate-pdf', queryParams, { responseType: 'blob' }).then(response => {
          if (response.status === 200) {
            this.downloadFile(response.data, `${this.selectedTemplate.name}(group list)`, 'pdf')
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error generating PDF',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async generateZip() {
      try {
        const queryParams = {
          group_list_template_id: this.selectedTemplate.id,
          group_type_id: this.groupTypeId,
          send_by_email: this.isSendByEmail,
          selected_email: this.selectedEmail,
        }
        await axios.post('/auth/group-list-templates/generate-zip', queryParams, { responseType: 'blob' }).then(response => {
          if (response.status === 200 && this.isSendByEmail === false) {
            this.downloadFile(response.data, `${this.selectedTemplate.name}(group type list)`, 'zip')
            this.closeModal()
          } else {
            this.showSuccessMessage = true
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error generating zip',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async generateGroupListTemplate(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(resolve => {
        this.$refs.generateGroupListTemplateRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.isLoading = true
            if (this.generateMode === 'groupType') {
              this.generateZip().finally(() => {
                this.isLoading = false
              })
            } else {
              this.generatePdf().finally(() => {
                this.isLoading = false
              })
            }
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
