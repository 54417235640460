<template>

  <b-row>

    <!-- Left Col: Card -->
    <b-col
      v-if="groupMainInfo"
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >

      <b-badge
        variant="primary"
        class="d-block p-75 mb-1"
      >
        <span class="mb-0 text-white">{{ groupMainInfo.name }}</span>
      </b-badge>

      <b-card
        no-body
        class="p-75"
      >

        <b-card-header class="d-flex justify-content-between align-items-center">
          <h5>
            Current Plan
          </h5>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled">
            <li>
              <span class="align-middle">Type: {{ groupMainInfo.type }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Program: {{ groupMainInfo.program }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Parent Group: {{ groupMainInfo.parent_group ? groupMainInfo.parent_group.name : '' }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Member Count: {{ groupMainInfo.member_count }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Status:
                <b-badge
                  :variant="`light-${resolveStatusVariant(groupMainInfo.status)}`"
                >
                  {{ groupMainInfo.status }}
                </b-badge>
              </span>
            </li>
            <li class="my-25">
              <span class="align-middle">Has Tuition: {{ groupMainInfo.adminRules ? groupMainInfo.adminRules.has_tuition_fee : '' }}</span>
            </li>
            <li
              v-for="row in groupMainInfo.customFields"
              :key="row.id"
              class="my-25"
            >
              <span class="align-middle">{{ row.label }}:
                <b-link
                  v-if="row.type === 'staff'"
                  :to="{ name: 'admin-staff-profile', params: { id: row.value.id } }"
                >{{ row.value.label }}</b-link>
                <span v-else>
                  {{ row.value }}
                </span>
              </span>
            </li>
          </ul>
        </b-card-body>

      </b-card>

      <b-card
        v-if="tagStatistics.length !== 0"
        no-body
        class="p-75"
      >
        <div
          v-for="tagSet in tagStatistics"
          :key="tagSet.id"
        >

          <b-card-header class="d-flex justify-content-between align-items-center">
            <h5>
              {{ tagSet.tag_set_name }}
            </h5>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled">
              <li
                v-for="(tag, index) in tagSet.tags"
                :key="index"
              >
                <span class="align-middle">{{ tag.name }}: {{ tag.applications_count }}</span>
              </li>
            </ul>
          </b-card-body>
        </div>
      </b-card>

    </b-col>

    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="table-header m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="mr-1 mb-0">Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :value="perPage"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @input="updatePerPage"
              />
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="w-100"
            >
              <v-select
                v-model="filterDataSession"
                :disabled="groupDetail.sessions.length === 0"
                :options="groupDetail.sessions"
                :reduce="item => item.id"
                label="name"
                value="id"
                :clearable="true"
                :placeholder="`Select ${sessionSemester(2)}`"
              />
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="w-100"
            >
              <treeselect
                v-model="filterDataTag"
                :multiple="true"
                :options="tagSetOptionsList"
                :default-expand-level="1"
                search-nested
                :disable-branch-nodes="true"
                placeholder="Select Tags"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="3"
            >
              <div class="d-flex align-items-center justify-content-end">
                <label class="mr-1 mb-0">Search</label>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search"
                  class="w-50"
                />

                <!-- Dropdown -->
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  toggle-class="p-0"
                  no-caret
                  right
                  class="h-100 ml-1"
                >

                  <template
                    #button-content
                  >
                    <feather-icon
                      icon="GridIcon"
                      size="19"
                      class="m-50"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="$can('add', permissionSubjects.GroupMember) && isGroupActive"
                    v-b-modal.add-staff-to-group
                  >
                    <span>Add Staff</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('add', permissionSubjects.GroupMember) && isGroupActive"
                    v-b-modal.add-camper-to-group
                  >
                    <span>Add {{ camperStudent(1) }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('update', permissionSubjects.Group)"
                    v-b-modal.edit-group
                  >
                    <span>Edit Group</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isGroupActive && $can('update', permissionSubjects.Group)"
                    v-b-modal.add-custom-fields
                  >
                    <span>Edit Custom Field</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="exportGroupCampers"
                  >
                    <span>Export to CSV</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.generate-letter
                  >
                    <span>Generate Letter</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.generate-group-list-template
                  >
                    <span>Group List Template</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.generate-badges
                  >
                    <span>Generate Badges</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="downloadBages"
                  >
                    <span>Download Badges</span>
                  </b-dropdown-item>
                </b-dropdown>

              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <div
                v-b-modal.select-ppa-columns
                class="d-flex align-items-center justify-content-end cursor-pointer mt-1"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="text-primary mr-1"
                />
                PPA Columns
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refInvoiceListTable"
          :items="tableItems"
          :fields="tableColumns"
          primary-key="id"
          :busy="isTableLoading"
          show-empty
          responsive
          sticky-header="75vh"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-sticky-head"
        >
          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <template #head(selected)>
            <div class="d-flex">
              <b-form-checkbox
                v-model="selectedAllOnThePage"
                inline
                @change="handleSelectAllUsers"
              />
              Selected
            </div>
          </template>

          <template #cell(selected)="data">
            <b-form-checkbox
              v-model="markedElements"
              :value="data.item.user_id"
              inline
              @change="handleElementsPerPageCheckbox"
            />
          </template>

          <!-- Column: first_name -->
          <template #cell(first_name)="data">
            <feather-icon
              v-if="data.item.visibility"
              icon="EyeIcon"
              size="16"
              class="align-middle text-body mr-1 cursor-pointer"
              @click="updateVisibility(data.item)"
            />
            <feather-icon
              v-else
              icon="EyeOffIcon"
              size="16"
              class="align-middle text-body mr-1 text-muted cursor-pointer"
              @click="updateVisibility(data.item)"
            />
            <b-avatar
              class="mr-1"
              size="32"
              :src="data.item.avatar"
            />
            <b-link
              :to="getUserViewRoutByType(data.item)"
            >{{ data.item.first_name }}</b-link>
          </template>

          <!-- Column: last_name -->
          <template #cell(last_name)="data">
            <b-link
              :to="getUserViewRoutByType(data.item)"
            >{{ data.item.last_name }}</b-link>
          </template>

          <!-- Column: age -->
          <template #cell(age)="data">
            {{ data.item.age }}
          </template>

          <!-- Column: role -->
          <template #cell(role)="data">
            <b-badge
              pill
              :variant="tagsColor(data.item.role)"
              class="text-capitalize"
            >
              {{ data.item.role }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="mx-auto p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="$can('remove' && 'add', permissionSubjects.GroupMember)"
                v-b-modal.move-member
                @click="memberForMoving = data.item.user_id"
              >
                <span>Move to Group</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('remove', permissionSubjects.GroupMember)"
                @click="removeApplication(data.item.application_id)"
              >
                <span>Remove Member</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center mt-2"
            >
              <b-dropdown
                id="dropdown-buttons"
                text="Bulk Actions"
                variant="outline-danger"
              >
                <b-dropdown-item
                  v-if="$can('add', permissionSubjects.GroupMember)"
                  v-b-modal.bulk-add-to-group-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Add To Group
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canSendBulkCommunication"
                  v-b-modal.bulk-communication-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Communication
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('create', permissionSubjects.Charge)"
                  v-b-modal.bulk-adding-charge-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Charge
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.bulk-generate-letter-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Generate Letter
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('assign', permissionSubjects.Tags)"
                  v-b-modal.bulk-tag-assign-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Tag Assign
                </b-dropdown-item>
              </b-dropdown>
              <span
                v-if="markedElements.length"
                class="ml-1 text-muted"
              >
                Selected users: {{ selectedUsersCount }}
              </span>
            </b-col>

          </b-row>
        </div>

      </b-card>

    </b-col>

    <!-- Add Camper To Group -->
    <add-camper-to-group-modal
      v-if="groupDetail.program_id"
      :group-id="groupId"
      :program-id="groupDetail.program_id"
      :sessions-list="groupDetail.sessions"
      :is-loading="isLoading"
      :validation-errors="validationAddMemberErrors"
      @ok="setCamperToGroup"
    />

    <!-- Add Staff To Group -->
    <add-staff-to-group-modal
      v-if="groupDetail.program_id"
      :group-id="groupId"
      :program-id="groupDetail.program_id"
      :is-loading="isLoading"
      :validation-errors="validationAddMemberErrors"
      @ok="setStaffToGroup"
    />

    <!-- Move Member To Group -->
    <b-modal
      id="move-member"
      ref="move-member"
      ok-title="Move"
      cancel-variant="outline-secondary"
      centered
      title="Move to Group"
      no-close-on-backdrop
      :ok-disabled="isLoading"
      @ok="moveMemberToGroupFromCurrent"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form>
          <b-form-group
            label="Group"
          >
            <v-select
              v-model="newGroupForMember"
              :options="allGroupsListForMoving"
              :reduce="item => item.value"
              label="text"
              :clearable="true"
              :placeholder="'Select group'"
            />
          </b-form-group>
        </b-form>
      </b-overlay>
    </b-modal>

    <!-- Edit Group -->
    <b-modal
      id="edit-group"
      ref="edit-group"
      centered
      title="Edit Group"
      ok-title="Save"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      :ok-disabled="isLoading"
      @show="openEditGroupModal"
      @hidden="resetGroupModal"
      @ok="handleEditGroupModal"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <validation-observer
          ref="groupRules"
          tag="form"
        >
          <b-form
            ref="form"
          >
            <b-row>

              <b-col
                sm="6"
                class="mb-1"
              >
                <b-form-group
                  label="Group Name"
                  label-for="group_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="group name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="groupDetail.name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="6"
                class="mb-1"
              >
                <b-form-group
                  label="Group Type"
                  label-for="group_type"
                >
                  <b-form-input
                    v-model="groupDetail.type"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    rules="required"
                  >
                    <v-select
                      v-model="groupDetail.status"
                      :options="groupStatusOptions"
                      :clearable="false"
                      placeholder="Select Status"
                      :reduce="status => status.value"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Parent Group"
                  label-for="parent_group"
                >
                  <b-form-input
                    v-model="groupDetail.parent_group.name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  :label="sessionSemester(2)"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="sessionSemester(2)"
                    rules="required"
                  >
                    <v-select
                      v-model="groupDetail.sessions"
                      :disabled="groupSessionsOptions.length === 0 || !groupDetail.parent_group"
                      :options="groupSessionsOptions"
                      label="name"
                      value="id"
                      multiple
                      :clearable="false"
                      :placeholder="fieldSessionStatus"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="groupDetail.sessions.length"
                sm="12"
                class="mt-2"
              >
                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="isCustomPricingAvailable"
                    name="checkbox-1"
                  >
                    Add custom pricing
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                v-if="groupDetail.sessions.length && isCustomPricingAvailable"
                sm="12"
                class="mt-2"
              >

                <template v-for="(session, index) in groupDetail.sessions">
                  <b-row
                    :key="index"
                    class="d-flex align-items-center mb-2"
                  >
                    <b-col
                      sm="6"
                      class="mb-1"
                    >
                      <p class="mt-1 mb-0">
                        {{ session.name }} addon
                      </p>
                    </b-col>

                    <b-col sm="6">
                      <label
                        class="d-flex d-inline align-items-center"
                      >
                        <strong class="mr-1 font-weight-bold">($)</strong>
                        <b-form-input
                          v-model="session.pivot_price"
                          type="number"
                          class="sm-4"
                          placeholder="0"
                          @input="session.price_was_changed = true"
                        />
                      </label>

                    </b-col>
                  </b-row>
                </template>
              </b-col>

              <template>
                <b-col
                  v-if="defaultCustomFields.length"
                  sm="12"
                  class="my-2"
                >
                  <hr>
                  <span>Group Type Custom Fields</span>
                </b-col>

                <group-custom-field-row-from-type
                  v-for="(item, index) in defaultCustomFields"
                  :key="item.frontID"
                  :fields-data="item"
                  :field-index="index"
                />

                <b-col
                  v-if="defaultCustomFields.length"
                  sm="12"
                >
                  <hr>
                </b-col>
              </template>

              <b-col
                v-if="uniqueCustomFields.length"
                sm="12"
                class="my-2"
              >
                <span>Group Custom Fields</span>
              </b-col>

              <group-custom-fields
                :fields-list="uniqueCustomFields"
              />

              <b-col
                sm="12"
              >
                <hr>
              </b-col>

              <b-col
                sm="12"
                class="mt-2"
              >
                <span>Auto-Assignments</span>
                <group-type-auto-assigns
                  :assigns-list="groupDetail.assignsList"
                  :custom-fields-list="uniqueCustomFields"
                />
              </b-col>

              <group-admin-rules-component
                :group-data="groupDetail.adminRules"
                :custom-fields-list="uniqueCustomFields"
                :assigns-list="groupDetail.assignsList"
                :action="'Edit'"
              />

            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- Add Custom Fields -->
    <edit-group-custom-fields-modal
      v-if="groupDetail.name"
      :program-id="groupDetail.program_id"
      :group-id="groupId"
      @reset="resetGroupModal"
    />

    <!-- Generate letter -->
    <b-modal
      id="generate-letter"
      ref="generate-letter"
      title="Generate Letter"
      centered
      no-close-on-backdrop
      ok-only
      ok-title="Generate"
      :ok-disabled="isLoading"
      @ok="generateLetter"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-form>
          <b-form-group
            label="User Type"
          >
            <v-select
              v-model="userType"
              :options="userTypeOptions"
              label="text"
              :clearable="false"
              placeholder="Select User Type"
            />
          </b-form-group>

          <b-form-group
            label="Letter Template"
          >
            <v-select
              v-model="letter"
              label="name"
              :placeholder="'Select letter'"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="letterTemplatesOptions"
            />
          </b-form-group>
          <p class="text-danger text-center">
            {{ errorMessage }}
          </p>
        </b-form>
      </b-overlay>
    </b-modal>

    <bulk-communicate-modal
      v-if="markedElements.length"
      :all-elements-checked="allUsersSeleted"
      :group-id="groupId"
      :marked-elements="markedElements"
      :total-elements-count="selectedUsersCount"
      :filter-name="'user_id'"
      @successfullySend="bulkCommunicateSuccess"
    />

    <bulk-add-to-group-modal
      v-if="markedElements.length"
      :all-elements-checked="allUsersSeleted"
      :group-id="groupId"
      :marked-elements="markedElements"
      :filter-name="'user_id'"
      :program-id="groupDetail.program_id"
      @successfullySend="bulkAddToGroupSuccess"
    />

    <bulk-adding-charge-modal
      v-if="markedElements.length && groupDetail"
      :all-elements-checked="allUsersSeleted"
      :group-id="groupId"
      :total-elements-count="selectedUsersCount"
      :marked-elements="markedElements"
      :filter-name="'user_id'"
      :program-id="groupDetail.program_id"
      @success="bulkCreateChargeSuccess"
    />

    <bulk-generate-letter-modal
      v-if="markedElements.length && groupDetail"
      :all-elements-checked="allUsersSeleted"
      :group-id="groupId"
      :total-elements-count="selectedUsersCount"
      :marked-elements="markedElements"
      :filter-name="'user_id'"
      :program-name="groupDetail.program"
      @success="bulkGenerateLetterSuccess"
    />

    <bulk-tag-assign-modal
      v-if="markedElements.length && groupDetail"
      :all-elements-checked="allUsersSeleted"
      :group-id="groupId"
      :total-elements-count="selectedUsersCount"
      :marked-elements="markedElements"
      :filter-name="'user_id'"
      :program-id="groupDetail.program_id"
      @success="bulkTagAssignSuccess"
    />

    <group-price-increased-modal
      @ok="saveChosenOptionForChargeGroupPrice"
    />

    <group-price-decreased-modal
      @ok="saveChosenOptionForChargeGroupPrice"
    />

<!--    <applications-for-group-auto-assign-modal-->
<!--      v-if="applicationsForAutoAssign.length"-->
<!--      :group-id="groupId"-->
<!--      :applications-list="applicationsForAutoAssign"-->
<!--      @updated="refetchData"-->
<!--    />-->

    <generate-group-list-template-modal
      v-if="groupDetail.type_id"
      :group-id="groupId"
      :group-type-id="groupDetail.type_id"
    />

    <generate-badges-modal
      :group-id="groupId"
    />

    <select-all-modal
      @selectOption="setSelectAllMode"
    />

    <select-ppa-columns
      :ppa-list="ppaList"
      @updateData="refetchData"
    />
  </b-row>

</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BTable, BPagination, BForm,
  BDropdown, BDropdownItem, BAvatar, BBadge, BModal, VBModal, BFormGroup, BLink, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters } from 'vuex'

import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/group/group-detail/groupDetailStoreModule'
import useGroupList from '@/views/admin/group/group-list/useGroupList'
import useGroupMembers from '@/views/admin/group/group-detail/useGroupMembers'
import useGroupDetail from '@/views/admin/group/group-detail/useGroupDetail'
import router from '@/router'
import GroupAdminRulesComponent from '@/views/admin/group/group-forms/components/GroupAdminRulesComponent.vue'
import EditGroupCustomFieldsModal from '@/views/admin/group/group-forms/EditGroupCustomFieldsModal.vue'
import BulkCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import BulkAddToGroupModal from '@/views/components/bulk-add-to-group/BulkAddToGroupModal.vue'
import BulkAddingChargeModal from '@/views/components/adding-charge-modal/BulkAddingChargeModal.vue'
import BulkGenerateLetterModal from '@/views/components/bulk-generate-letter/BulkGenerateLetterModal.vue'
import BulkTagAssignModal from '@/views/components/bulk-tag-assign/BulkTagAssignModal.vue'
import GroupPriceIncreasedModal from '@/views/components/group-price-changed-modal/GroupPriceIncreasedModal.vue'
import GroupPriceDecreasedModal from '@/views/components/group-price-changed-modal/GroupPriceDecreasedModal.vue'
import AddCamperToGroupModal from '@/views/admin/group/group-detail/AddCamperToGroupModal.vue'
import AddStaffToGroupModal from '@/views/admin/group/group-detail/AddStaffToGroupModal.vue'
import GroupCustomFieldRowFromType from '@/views/admin/group/group-forms/components/GroupCustomFieldRowFromType.vue'
import GroupCustomFields from '@/views/admin/group/group-forms/components/GroupCustomFields.vue'
import GroupTypeAutoAssigns from '@/views/admin/group/group-forms/components/GroupTypeAutoAssigns.vue'
import GenerateGroupListTemplateModal from '@/views/components/generate-group-list-template-modal/GenerateGroupListTemplateModal.vue'
import GenerateBadgesModal from '@/views/components/generate-badges-modal/GenerateBadgesModal.vue'
import SelectAllModal from '@/views/components/select-all-modal/SelectAllModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import SelectPpaColumns from '../group-list/modals/SelectPpaColumns.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BBadge,
    BModal,
    BLink,
    BFormCheckbox,
    BOverlay,

    TableSpinner,

    vSelect,
    Treeselect,
    GroupAdminRulesComponent,
    EditGroupCustomFieldsModal,
    BulkCommunicateModal,
    BulkAddToGroupModal,
    BulkAddingChargeModal,
    BulkGenerateLetterModal,
    BulkTagAssignModal,
    GroupPriceIncreasedModal,
    GroupPriceDecreasedModal,
    AddCamperToGroupModal,
    AddStaffToGroupModal,
    GroupCustomFieldRowFromType,
    GroupCustomFields,
    GroupTypeAutoAssigns,
    GenerateGroupListTemplateModal,
    GenerateBadgesModal,
    SelectAllModal,
    SelectPpaColumns,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition, downloadExportFile],
  data() {
    return {
      defaultCustomFields: [],
      uniqueCustomFields: [],
      isCustomPricingAvailable: true,
      newGroupForMember: null,
      memberForMoving: null,
      allStaffList: [{ value: null }],
      selectedGroupType: null,
      nextTodoId: 2,
      fieldSessionStatus: 'Loading...',
      staffFieldStatus: 'Loading...',
      userTypeOptions: ['Staff', 'Camper'],
      userType: '',
      letterTemplatesOptions: [],
      letter: null,
      errorMessage: '',
      validationAddMemberErrors: [],
      markedElements: [],
      required,
      changedPriceData: {
        increase: {
          groupSessions: [],
          showModal: false,
          chosenOption: null,
        },
        decrease: {
          groupSessions: [],
          showModal: false,
          chosenOption: null,
        },
      },
      selectedAllOnThePage: false,
      allUsersSeleted: false,
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'app-group-details/getProgramId',
      groupId: 'app-group-details/getGroupId',
      groupMainInfo: 'app-group-details/getGroupMainInfo',
      groupDetail: 'app-group-details/getGroupDetail',
      groupSessionsOptions: 'app-group-details/getGroupSessionsOptions',
      allGroupsListForMoving: 'app-group-details/getAllGroupsListForMoving',
      tagSetOptionsList: 'app-group-details/getTagSetOptionsList',
      tagStatistics: 'app-group-details/getTagStatistics',
      groupTypeFieldsList: 'app-group-details/getGroupTypeFieldsList',
      groupCustomFieldsList: 'app-group-details/getGroupCustomFieldsList',
      ppaList: 'app-group-details/getPpaList',
    }),
    isLoading: {
      get() {
        return store.getters['app-group-details/getIsLoading']
      },
      set(val) {
        store.commit('app-group-details/SET_LOADING_STATUS', val)
      },
    },
    applicationsForAutoAssign: {
      get() {
        return store.getters['app-group-details/getApplicationsForAutoAssign']
      },
      set(val) {
        store.commit('app-group-details/SET_APPLICATIONS_FOR_GROUP_AUTO_ASSIGN', val)
      },
    },
    isGroupActive() {
      return this.groupMainInfo.status === 'Active'
    },
    selectedUsersCount() {
      return this.allUsersSeleted ? this.dataMeta.of : this.markedElements.length
    },
    defaultPragram() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
    },
    filterDataTag() {
      this.fetchFilterTagStatistics()
    },
    searchQuery() {
      this.fetchFilterTagStatistics()
    },
    filterDataSession() {
      this.fetchFilterTagStatistics()
    },
    'refInvoiceListTable.localItems': function fn(val) {
      if (val && this.allUsersSeleted === true) {
        this.markElementsOnPage(true)
      }
      this.handleElementsPerPageCheckbox()
    },
    defaultPragram() {
      this.$router.replace({ name: 'admin-groups' })
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-group-details'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchMembersList,
      tableColumns,
      tableItems,
      isTableLoading,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      filterDataSession,
      filterDataTag,
      refetchData,

    } = useGroupMembers(root)

    const {
      groupStatusOptions,
      resolveStatusVariant,
    } = useGroupList()

    const {
      addMemberToGroup,
      moveMemberToGroup,
      removeMemberFromGroup,
      generateUsersLetters,
      updateUserVisibility,
      addApplicationToGroup,
      moveApplicationToGroup,
      removeApplicationFromGroup,
    } = useGroupDetail()

    return {
      fetchMembersList,
      tableColumns,
      tableItems,
      isTableLoading,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      groupStatusOptions,
      resolveStatusVariant,
      filterDataSession,
      filterDataTag,
      addMemberToGroup,
      moveMemberToGroup,
      removeMemberFromGroup,
      generateUsersLetters,
      updateUserVisibility,
      addApplicationToGroup,
      moveApplicationToGroup,
      removeApplicationFromGroup,

      refetchData,
    }
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)

    store.commit('app-group-details/SET_GROUP_ID', router.currentRoute.params.id)
    store.commit('app-group-details/SET_LOADING_STATUS', true)
    if (this.groupId) {
      await this.fetchMembersList()
      await this.fetchPpaList()
      await this.setGroupData()
      await this.fetchSessionGroup()
      await this.prepareGroupsForMoving()
      await this.fetchTagSetsListOptions()

      this.letterTemplatesOptions = await this.$store.dispatch('app-group-details/fetchLetterTemplates')
        .then(response => response.data.data)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initTrHeight()
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    async setGroupData() {
      await store.dispatch('app-group-details/fetchGroup')
    },
    async fetchSessionGroup(changedGroup) {
      if (changedGroup) this.groupDetail.sessions = []

      if (this.groupDetail.parent_group) {
        this.fieldSessionStatus = 'Loading...'
        await store.dispatch('app-group-details/fetchSessions')
          .then(() => {
            this.fieldSessionStatus = `Select ${this.sessionSemester(2)}`
          })
      }
    },
    async prepareGroupsForMoving() {
      await store.dispatch('app-group-details/fetchGroupsForMoving')
    },
    async fetchTagSetsListOptions() {
      await store.dispatch('app-group-details/prepareTagSetsListOptions')
    },
    async fetchFilterTagStatistics() {
      if (this.filterDataTag.length === 0) {
        store.commit('app-group-details/SET_TAG_STATISTICS', [])
        return
      }
      const queryParams = {
        tag_id: this.filterDataTag,
        group_id: this.groupId,
      }

      if (this.searchQuery) queryParams.userName = this.searchQuery
      if (this.filterDataSession) queryParams.sessionId = this.filterDataSession

      await store.dispatch('app-group-details/fetchTagStatistics', { queryParams })
    },
    async clearChangedPriceData() {
      this.changedPriceData.increase.groupSessions = []
      this.changedPriceData.increase.showModal = false
      this.changedPriceData.increase.chosenOption = null
      this.changedPriceData.decrease.groupSessions = []
      this.changedPriceData.decrease.showModal = false
      this.changedPriceData.decrease.chosenOption = null
    },
    getUserViewRoutByType(item) {
      if (item.is_student) {
        return { name: 'admin-application-edit', params: { id: item.application_id, type: this.camperStudent(1) } }
      }
      return { name: 'admin-application-edit', params: { id: item.application_id, type: 'Staff' } }
    },
    // async prepareApplicationsForGroupAutoAssign() {
    //   this.applicationsForAutoAssign = []
    //   this.isLoading = true
    //   await store.dispatch('app-group-details/fetchApplicationsForGroupAutoAssign')
    //   this.isLoading = false
    // },
    async openEditGroupModal() {
      this.defaultCustomFields = this.groupTypeFieldsList
      this.uniqueCustomFields = this.groupCustomFieldsList

      const staffFields = this.defaultCustomFields.filter(field => field.type === 'staff')

      if (staffFields.length) {
        await this.$store.dispatch('app-group-details/fetchStaffList', {
          programId: this.programId,
        })
          .then(response => {
            const options = response.data.data.map(item => ({ id: item.user.id, label: item.user.full_name }))
            staffFields.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.options = options
            })
          })
      }
    },
    prepareCustomFieldsForUpdate() {
      const defaults = this.defaultCustomFields.map(row => ({
        label: row.label,
        attribute: row.name,
        value: row.value,
        group_type_custom_field_id: row.group_type_custom_field_id,
      }))

      const unique = this.uniqueCustomFields.map(row => ({
        label: row.label,
        attribute: row.attribute ? row.attribute : row.name,
        value: row.value,
        id: row.id,
      }))

      return defaults.concat(unique)
    },
    async handleEditGroupModal(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.groupRules.validate().then(success => {
          if (success) {
            resolve(true)

            this.clearChangedPriceData()
            const unchangedGroupPriceSessions = this.groupDetail.sessions.filter(session => !session.price_was_changed || +session.pivot_price === session.start_price)

            if (!this.isCustomPricingAvailable || unchangedGroupPriceSessions.length === this.groupDetail.sessions.length || !this.groupDetail.member_count) {
              this.updateGroupInfo()
              return
            }

            this.changedPriceData.increase.groupSessions = this.groupDetail.sessions
              .filter(session => session.price_was_changed && session.pivot_price > session.start_price)
              .map(session => ({
                ...session,
                difference: session.pivot_price - session.start_price,
              }))
            if (this.changedPriceData.increase.groupSessions.length) {
              this.changedPriceData.increase.showModal = true
              this.$bvModal.show('group-price-increased')
            }

            this.changedPriceData.decrease.groupSessions = this.groupDetail.sessions
              .filter(session => session.price_was_changed && session.pivot_price < session.start_price)
              .map(session => ({
                ...session,
                difference: session.start_price - session.pivot_price,
              }))
            if (this.changedPriceData.decrease.groupSessions.length) {
              this.changedPriceData.decrease.showModal = true
              this.$bvModal.show('group-price-decreased')
            }
          } else {
            reject()
          }
        })
      })
    },
    async saveChosenOptionForChargeGroupPrice(chosenChargeOptionData) {
      this.changedPriceData[chosenChargeOptionData.action].chosenOption = chosenChargeOptionData.option
      this.changedPriceData[chosenChargeOptionData.action].showModal = false

      if (this.changedPriceData.increase.showModal === false && this.changedPriceData.decrease.showModal === false) {
        await this.updateGroupInfo()
      }
    },
    async updateGroupInfo() {
      const payload = {
        isCustomPricingAvailable: this.isCustomPricingAvailable,
        changedPriceData: this.changedPriceData,
        customFields: this.prepareCustomFieldsForUpdate(),
        vm: this,
      }

      await store.dispatch('app-group-details/updateGroup', payload)

      // await this.prepareApplicationsForGroupAutoAssign()
      //
      // if (this.applicationsForAutoAssign.length > 0) {
      //   this.$bvModal.show('applications-for-group-auto-assign')
      // }
    },
    setMemberToGroup(member = null, sessions = null) {
      this.validationAddMemberErrors = []
      this.isLoading = true

      const formData = {
        group_id: this.groupId,
        user_id: member.value,
        program_id: this.groupDetail.program_id,
      }

      if (sessions) {
        formData.sessions = sessions
      }

      this.addMemberToGroup(formData)
        .then(response => {
          if (response.status === 422) {
            const validationErrors = Object.values(response.data.errors)
            this.validationAddMemberErrors = validationErrors.flat()
          } else {
            store.commit('app-group-details/SET_GROUP_DATA', response.data)
            this.closeAddMemberModal()
            this.refetchData()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setApplicationToGroup(member = null, sessions = null) {
      this.validationAddMemberErrors = []
      this.isLoading = true

      const formData = {
        group_id: this.groupId,
        application_id: member.value,
        program_id: this.groupDetail.program_id,
      }

      if (sessions) {
        formData.sessions = sessions
      }

      this.addApplicationToGroup(formData)
        .then(response => {
          if (response.status === 422) {
            const validationErrors = Object.values(response.data.errors)
            this.validationAddMemberErrors = validationErrors.flat()
          } else {
            store.commit('app-group-details/SET_GROUP_DATA', response.data)
            this.closeAddMemberModal()
            this.refetchData()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setStaffToGroup(data) {
      this.setMemberToGroup(data.member)
    },
    setCamperToGroup(data) {
      this.setApplicationToGroup(data.member, data.sessions)
    },
    initTrHeight() {
      this.trSetHeight(null)
    },
    async moveMemberToGroupFromCurrent(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.isLoading = true

      const formData = {
        group_id: this.newGroupForMember,
        user_id: this.memberForMoving,
        old_group_id: this.groupId,
      }

      const response = await this.moveMemberToGroup(formData)
      if (response.data) {
        this.$nextTick(() => {
          this.$refs['move-member'].toggle('#toggle-btn')
        })
        store.commit('app-group-details/SET_GROUP_DATA', response.data)
        this.refetchData()
      }
      this.isLoading = false
      this.newGroupForMember = null
      this.memberForMoving = null
    },
    async removeMember(userId) {
      const formData = {
        group_id: this.groupId,
        user_id: userId,
        program_id: this.groupDetail.program_id,
        sessions: this.groupDetail.sessions.map(session => session.id),
      }
      await this.removeMemberFromGroup(formData)
      this.refetchData()
    },
    async removeApplication(applicationId) {
      const formData = {
        group_id: this.groupId,
        application_id: applicationId,
        program_id: this.groupDetail.program_id,
        sessions: this.groupDetail.sessions.map(session => session.id),
      }
      await this.removeApplicationFromGroup(formData)
      this.refetchData()
    },
    async exportGroupCampers() {
      const formData = {
        groupId: this.groupId,
      }
      await this.$store.dispatch('app-group-details/exportCampers', formData)
        .then(response => {
          const filename = `${this.groupDetail.name}_${this.camperStudent(1)}-export`
          this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'csv')
        })
    },
    tagsColor(tag) {
      if (tag === 'staff') return 'light-primary'
      if (tag === 'child') return 'light-info'
      return 'light-primary'
    },
    async generateLetter(bvModalEvt) {
      bvModalEvt.preventDefault()

      const type = this.userType.toLowerCase()
      const queryParams = {
        letter_template_id: this.letter.id,
        group_id: this.groupId,
        type,
      }

      this.isLoading = true
      await this.generateUsersLetters(queryParams).then(response => {
        if (response.status === 200) {
          const filename = `${this.letter.name}-${type}-${this.groupDetail.name}-${moment().format('YYYY-MM-DD')}`
          this.downloadFile(response.data, filename.replace(/\s/g, '-'), 'zip')
          this.isLoading = false
          return
        }
        this.errorMessage = response.statusText
        this.isLoading = false
      })
    },
    resetGroupModal() {
      store.commit('app-group-details/RESET_GROUP_MODAL_DATA')
    },
    closeAddMemberModal() {
      this.validationAddMemberErrors = []
      this.$bvModal.hide('add-camper-to-group')
      this.$bvModal.hide('add-staff-to-group')
    },
    updatePerPage(val) {
      localStorage.setItem('groupUsersPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('groupUsersPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataSession = query.filterDataSession || ''
      if (query.filterDataTag) {
        this.filterDataTag = query.filterDataTag
      }
    },
    async bulkCommunicateSuccess() {
      this.resetMarkedElements()
      this.$bvModal.hide('bulk-communication-modal')
      this.refetchData()
    },
    async bulkAddToGroupSuccess() {
      this.resetMarkedElements()
      this.$bvModal.hide('bulk-add-to-group-modal')
      this.refetchData()
    },
    async bulkCreateChargeSuccess() {
      this.resetMarkedElements()
      this.$bvModal.hide('bulk-adding-charge-modal')
      this.refetchData()
    },
    async bulkGenerateLetterSuccess() {
      this.resetMarkedElements()
      this.$bvModal.hide('bulk-generate-letter-modal')
    },
    async bulkTagAssignSuccess() {
      this.resetMarkedElements()
      this.$bvModal.hide('bulk-tag-assign-modal')
    },
    resetMarkedElements() {
      this.markedElements = []
      this.allUsersSeleted = false
      this.selectedAllOnThePage = false
    },
    async updateVisibility(user) {
      const payload = {
        queryParams: {
          application_id: user.application_id,
          visibility: !user.visibility,
        },
        vm: this,
      }

      await store.dispatch('app-group-details/updateUserVisibility', payload)

      this.refetchData()
    },
    async downloadBages() {
      try {
        const queryParams = {
          groups: [{ group_id: this.groupId }],
        }

        await axios.post('/auth/download-badges', queryParams, { responseType: 'blob' }).then(response => {
          if (response.status === 200) {
            this.downloadFile(response.data, `${this.groupDetail.name}(badges)`, 'zip')
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error downloading badges',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    handleSelectAllUsers(val) {
      if (val) {
        this.$bvModal.show('selectAllModal')
      } else {
        this.markedElements = []
        this.allUsersSeleted = false
      }
    },
    setSelectAllMode(mode) {
      if (mode === 'all') {
        this.allUsersSeleted = true
        this.markElementsOnPage(true)
      } else {
        this.markElementsOnPage(true)
      }
    },
    markElementsOnPage(val) {
      const elements = this.markedElements
      if (val) {
        this.refInvoiceListTable.localItems.forEach(item => {
          const index = elements.indexOf(item.user_id)
          if (index < 0) {
            elements.push(item.user_id)
          }
        })
      } else {
        this.refInvoiceListTable.localItems.forEach(item => {
          const index = elements.indexOf(item.user_id)
          if (index >= 0) {
            elements.splice(index, 1)
          }
        })
      }
      this.markedElements = elements
    },
    handleElementsPerPageCheckbox() {
      let counter = 0
      this.refInvoiceListTable.localItems.forEach(item => {
        if (this.markedElements.indexOf(item.user_id) !== -1) {
          counter += 1
        }
      })

      this.selectedAllOnThePage = !!(this.refInvoiceListTable.localItems && counter === this.refInvoiceListTable.localItems.length)

      if (this.allUsersSeleted && !this.selectedAllOnThePage) {
        this.allUsersSeleted = false
      }
    },
    async fetchPpaList() {
      try {
        await this.$store.dispatch('app-group-details/fetchPpaList', { programId: this.programId })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fatching PPA list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border: 1px solid #ea5455;
    }
  }
</style>
